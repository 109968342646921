<script lang="ts" setup>
import SectionComponent from '@/components/resume/SectionComponent.vue';
import WorkComponent from '@/components/resume/WorkComponent.vue';
import SchoolComponent from '@/components/resume/SchoolComponent.vue';
import { resume } from '@/data/resume.ts';
import EmailComponent from '@/components/common/EmailComponent.vue';
import useAsset from '@/composables/useAsset.ts';
import LanguagesComponent from '@/components/resume/LanguagesComponent.vue';

defineProps({
  europass: Boolean,
});

const areaSkills = resume.skills.filter(s => s.level === 'area');
const technicalSkills = resume.skills.filter(s => s.level === 'technical');

const appUrl = import.meta.env.VITE_APP_URL;

</script>

<template>
  <div class="flex justify-center screen:bg-base-200">
    <div
      :id="europass ? 'europass' : 'resume'"
      class="A4 bg-base-100 screen:shadow-md text-sm"
      itemscope
      itemtype="https://schema.org/Person"
    >
      <header :class="{ 'bg-gray-100 p-5': europass}" class="text-left mb-3">
        <div
          v-if="europass"
          :style="{ backgroundImage: `url(${useAsset('europass_logo.png')})` }"
          class="w-[56mm] h-[11mm] bg-cover bg-no-repeat bg-left ml-auto"
        />
        <h1
          :class="{
            'font-extrabold border-b-2 border-b-gray-400 text-xl text-gray-700 pb-3 mb-3': europass,
            'text-3xl text-center': !europass,
          }"
        >
          <span class="font-semibold uppercase" itemprop="name">{{ resume.basics.name }}</span>
          <small v-if="!europass" class="block" itemprop="jobTitle">{{ resume.basics.label }}</small>
        </h1>
        <div
          :class="{
            'justify-center': !europass,
          }"
          class="flex flex-row flex-wrap divide-x-2"
        >
          <div class="px-2">
            <span v-if="europass" class="font-bold pr-2">Email address:</span>
            <EmailComponent :email="resume.basics.email"/>
          </div>
          <div class="px-2" itemprop="address" itemscope itemtype="https://schema.org/PostalAddress">
            <span v-if="europass" class="font-bold pr-2">Address:</span>
            <span itemprop="addressLocality">{{ resume.basics.location.city }}</span>,
            <span itemprop="addressCountry">{{ resume.basics.location.countryCode }}</span>
          </div>
          <meta :content="appUrl" itemprop="url"/>
          <div
            v-for="profile in resume.basics.profiles"
            :key="profile.network"
            class="px-2"
          >
            <span v-if="europass" class="font-bold pr-2">{{ profile.network }}:</span>
            <a :href="profile.url" class="text-primary" itemprop="sameAs">
              {{ profile.url.replace('https://', '') }}
            </a>
          </div>
          <div v-if="!europass" class="px-2 print:hidden">
            <router-link :to="{ name: 'europass' }">Europass</router-link>
          </div>
        </div>
      </header>

      <SectionComponent :europass="europass" :title="europass ? 'About me' : 'Summary'">
        <div itemprop="hasOccupation" itemscope itemtype="https://schema.org/Occupation">
          <ul class="list-disc pl-5">
            <li
              v-for="summary in resume.basics.summary.split('\n')"
              :key="summary"
            >
              <span itemprop="responsibilities">{{ summary }}</span>
            </li>
          </ul>
        </div>
      </SectionComponent>

      <SectionComponent v-if="!europass" title="Areas of Expertise">
        <div itemprop="hasOccupation" itemscope itemtype="https://schema.org/Occupation">
          <ul class="list-disc pl-5 grid grid-cols-2 sm:grid-cols-3">
            <li
              v-for="skill in areaSkills"
              :key="skill.name"
            >
              <span itemprop="qualifications">{{ skill.name }}</span>
            </li>
          </ul>
        </div>
      </SectionComponent>

      <SectionComponent :europass="europass" :title="europass ? 'Work Experience' : 'Professional Experience'">
        <div class="divide-y divide-base-200">
          <div
            v-for="work in resume.work"
            :key="work.company"
            class=""
          >
            <WorkComponent :europass="europass" :work="work"/>
          </div>
        </div>
      </SectionComponent>

      <SectionComponent
        :europass="europass"
        :title="europass ? 'Education and Training' : 'Education'"
        class="break-inside-avoid"
      >
        <div
          v-for="education in resume.education"
          :key="education.institution"
        >
          <SchoolComponent :education="education" :europass="europass"/>
        </div>
      </SectionComponent>

      <SectionComponent v-if="europass" :europass="true" title="Language Skills">
        <LanguagesComponent :languages="resume.languages"/>
      </SectionComponent>

      <SectionComponent :europass="europass" :title="europass ? 'Digital Skills' : 'Technical Skills'">
        <div itemprop="hasOccupation" itemscope itemtype="https://schema.org/Occupation">
          <div class="text-center">
            <span
              v-for="skill in technicalSkills"
              :key="skill.name"
              class="text-xs"
            >
              <span itemprop="skills">{{ skill.name }}</span>
              <span v-if="technicalSkills.indexOf(skill) !== technicalSkills.length - 1"> • </span>
            </span>
          </div>
        </div>
      </SectionComponent>
    </div>
  </div>
</template>

<style scoped>
.open-sans-europass {
  font-family: "Open Sans", sans-serif;
  font-optical-sizing: auto;
  font-weight: 350;
  font-style: normal;
  font-variation-settings: "wdth" 100;
  line-height: 1.2;
}

#resume {
  a {
    @apply text-primary;
  }
}

#europass {
  @apply open-sans-europass;

  a {
    @apply text-blue-800;
  }

  strong {
    @apply font-bold;
  }
}

@media screen {
  .A4 {
    height: 100%;
    margin: 0 auto;
  }

  #europass.A4 {
    width: 210mm;
    padding: 0;

    header {
      padding: 5mm;
    }

    section {
      margin: 5mm;
    }
  }

  #resume.A4 {
    padding: 20px;
    width: auto;
  }

  @media (min-width: 768px) {
    #resume.A4 {
      width: 90%;
    }
  }

  @media (min-width: 1024px) {
    #resume.A4 {
      width: 210mm;
    }
  }

}

@media print {
  .A4 {
    width: 100%;
    padding: 0;
    margin: 0;
  }
}
</style>
